.background{
    background: url("../public/image/20220204_051313.jpg") no-repeat;
    background-size: cover; 
    background-attachment: fixed;
}

.main-body{
    background-color: rgb(255, 246, 226);
    height: 100%;
    
}

h1{
    padding-bottom: 3vh;
}

.logo1{
    height: 5vh;
}

div.gallery {
    margin: 5px;
    width: 50%;
    margin: 40px auto;
    
}
  
/* div.gallery img {
    width: 100%;
    height: auto;
} */
  
div.desc {
    padding: 15px;
    text-align: center;
}

#social-media{
    background-color: rgba(115, 115, 115, 0.515);
    width: 100%;
    bottom:0;
    margin-top: 60px;
}

#social-media .box{
    margin: auto;
    max-width: 800px;
    text-align: center;
}

#social-media a i{
    font-size: 2rem;
    padding: 10px 30px 10px 30px; 
}

#social-media a i:hover{
    transition: 1.2s;
    transform: rotate(360deg);   
}

.mask {
    margin: auto;
    position: relative;
    margin-bottom: 10px;
    
}

.mask::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to top, rgb(255, 246, 226), transparent);

    
}

.mask img {
    
    width: 500px;
    position: relative;
    opacity: 0.65;
    
}

.showImage{
    padding-bottom: 20px;
}

.showImage .asd{
    padding-bottom: 40px;
}

.showImage img{
    width: 100%;
    height: auto;
}

.mask1 {
    margin: auto;
    position: relative;
    margin-bottom: 10px;
}

.mask1::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgb(255, 246, 226), transparent);

    
}

.mask1 img {
    width: 500px;
    position: relative;
    opacity: 0.65;
    
}


.coin {

    animation: rotate 5s linear;
    border-radius: 50%;
  }
  
  .coin:hover {
    animation-play-state: paused;
  }
  
  @keyframes rotate {
    0% {
      transform: rotateY(0deg);
    }
  
    100% {
      transform: rotateY(360deg);
    }
  }


  .newsletter123{
    font-size: 22px;
    text-decoration:none;
  }


.pp-gallery {
min-height: 500px; }
.pp-gallery .card-columns .card {
border: none;
margin-bottom: 0;
border-radius: none; }
@media (max-width: 2500px) {
.pp-gallery .card-columns {
-moz-column-count: 3;
    column-count: 3; } }
@media (max-width: 1000px) {
.pp-gallery .card-columns {
-moz-column-count: 1;
    column-count: 1; } }
.pp-gallery .card figure {
position: relative;
overflow: hidden;
margin: 0;
text-align: center; 
background: rgb(255, 246, 226);}

.pp-gallery .card figure img {
position: relative;
display: block;
max-width: 100%;
opacity: 1; }
.pp-gallery .card figure figcaption {
padding: 2em;
text-transform: uppercase;
font-size: 1.25em;
-webkit-backface-visibility: hidden;
backface-visibility: hidden; }
.pp-gallery .card figure figcaption,
.pp-gallery .card figure figcaption > a {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%; }
.pp-gallery .card figure figcaption > a {
z-index: 1000;
text-indent: 200%;
white-space: nowrap;
font-size: 0;
opacity: 0; }
.pp-gallery .card figure .h4 {
word-spacing: -0.15em;
font-weight: 600; }
.pp-gallery .card figure .h4,
.pp-gallery .card figure p {
margin: 0; }
.pp-gallery .card figure p {
letter-spacing: 1px;
font-size: 60%; }
.pp-gallery figure.pp-effect figcaption {
padding: 12px; }
.pp-gallery figure.pp-effect figcaption::before,
.pp-gallery figure.pp-effect figcaption::after {
position: absolute;
content: '';
opacity: 0; }
.pp-gallery figure.pp-effect figcaption::before {
top: 50px;
right: 30px;
bottom: 50px;
left: 30px;
border-top: 1px solid #fff;
border-bottom: 1px solid #fff;
transform: scale(0, 1);
transform-origin: 0 0; }
.pp-gallery figure.pp-effect figcaption::after {
top: 30px;
right: 50px;
bottom: 30px;
left: 50px;
border-right: 1px solid #fff;
border-left: 1px solid #fff;
transform: scale(1, 0);
transform-origin: 100% 0; }
.pp-gallery figure.pp-effect .h4 {
padding-top: 26%;
transition: transform 0.35s;
opacity: 0; }
.pp-gallery figure.pp-effect p {
padding: 0.5em 2em;
text-transform: none;
opacity: 0;
transform: translate3d(0, -10px, 0); }
.pp-gallery figure.pp-effect img,
.pp-gallery figure.pp-effect .h4 {
transform: translate3d(0, -25px, 0); }
.pp-gallery figure.pp-effect img,
.pp-gallery figure.pp-effect figcaption::before,
.pp-gallery figure.pp-effect figcaption::after,
.pp-gallery figure.pp-effect p {
transition: opacity 0.35s, transform 0.35s; }
.pp-gallery figure.pp-effect:hover img {
opacity: 1;
transform: translate3d(0, 0, 0); }
.pp-gallery figure.pp-effect:hover figcaption::before,
.pp-gallery figure.pp-effect:hover figcaption::after {
opacity: 1;
transform: scale(1); }
.pp-gallery figure.pp-effect:hover figcaption:before {
background: rgba(31, 30, 30, 0.5); }
.pp-gallery figure.pp-effect:hover .h4,
.pp-gallery figure.pp-effect:hover p {
opacity: 1;
transform: translate3d(0, 0, 0);
color: #FFFFFF; }
.pp-gallery figure.pp-effect:hover figcaption::after,
.pp-gallery figure.pp-effect:hover .h4,
.pp-gallery figure.pp-effect:hover p,
.pp-gallery figure.pp-effect:hover img {
transition-delay: 0.15s; }
@media (max-width: 768px) and (min-width: 578px) {
.pp-gallery figure.pp-effect:hover .h4 {
padding-top: 20%;
font-size: 16px; } }
@media (max-width: 375px) {
.pp-gallery figure.pp-effect:hover .h4 {
padding-top: 20%;
font-size: 16px; } }


.privacyPolicy {
    margin: 15px;
}

.privacyPolicy .wordFrame {
    border: rgb(109, 221, 39) solid; 
    padding: 5px;
}